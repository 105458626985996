import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'Home Page | Make It So - Production and Event Realisation',
      metaTags: [
        {
          property: 'og:title',
          content: 'The home page of Make It So.',
        },
        {
          name: 'description',
          content:
            'The home page of Make It So, a production and events realisation company based in the United Kingdom.',
        },
        {
          property: 'og:description',
          content:
            'The home page of Make It So, a production and events realisation company based in the United Kingdom.',
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "home" */ /* webpackPrefetch: true */ '../views/Home.vue'
      ),
  },
  {
    path: '/about-us',
    name: 'About',
    meta: {
      title: 'About Us | Make It So - Production and Event Realisation',
      metaTags: [
        {
          property: 'og:title',
          content: 'About Make It So',
        },
        {
          name: 'description',
          content:
            'Make It\'s So\'s central mission statement and more about who we are.',
        },
        {
          property: 'og:description',
          content:
            'Make It\'s So\'s central mission statement and more about who we are.',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about_us" */ '../views/AboutUs.vue'),
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    meta: {
      title: 'Portfolio | Make It So - Production and Event Realisation',
      metaTags: [
        {
          property: 'og:title',
          content: 'Client Portfolio Page',
        },
        {
          name: 'description',
          content:
            'A gallery of clickable posters that shows the clients Make It So have worked with',
        },
        {
          property: 'og:description',
          content:
            'A gallery of clickable posters that shows the clients Make It So have worked with',
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "portfolio" */ /* webpackPrefetch: true */ '../views/Portfolio.vue'
      ),
  },
  {
    path: '/what-we-do/exhibitions',
    name: 'Exhibitions',
    meta: {
      title: 'Exhibitions | Make It So - Production and Event Realisation',
      metaTags: [
        {
          property: 'og:title',
          content: 'Exhibitions Page',
        },
        {
          name: 'description',
          content:
            'A description of Make It So\'s envolvement in the global exhibition space.',
        },
        {
          property: 'og:description',
          content:
            'A description of Make It So\'s envolvement in the global exhibition space.',
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "exhibitions" */ '../views/whatwedo/Exhibitions.vue'
      ),
  },
  {
    path: '/what-we-do/exhibitions/ipad-interactive-voting-system',
    name: 'iPad Interactive Voting System',
    meta: {
      title: 'iPad Interactive Voting System | Make It So - Production and Event Realisation',
      metaTags: [
        {
          property: 'og:title',
          content: 'iPad Interactive Voting System Page',
        },
        {
          name: 'description',
          content:
            'A page detailing information about Make It So Presents, an iPad Interactive Voting System',
        },
        {
          property: 'og:description',
          content:
            'A page detailing information about Make It So Presents, an iPad Interactive Voting System',
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "ipad-interactive-voting-system" */ '../views/whatwedo/IpadInteractiveVotingSystem.vue'
      ),
  },
  {
    path: '/what-we-do/exhibitions/keypad-interactive-voting-system',
    name: 'Keypad Interactive Voting System',
    meta: {
      title: 'Keypad Interactive Voting System | Make It So - Production and Event Realisation',
      metaTags: [
        {
          property: 'og:title',
          content: 'Keypad Interactive Voting System Page',
        },
        {
          name: 'description',
          content:
            'A page detailing information about Make It So\'s Keypad Interactive Voting System',
        },
        {
          property: 'og:description',
          content:
            'A page detailing information about Make It So\'s Keypad Interactive Voting System',
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "keypad-interactive-voting-system" */ '../views/whatwedo/KeypadInteractiveVotingSystem.vue'
      ),
  },
  {
    path: '/what-we-do/exhibitions/virtual-meetings',
    name: 'Virtual Meetings',
    meta: {
      title: 'Virtual Meetings | Make It So - Production and Event Realisation',
      metaTags: [
        {
          property: 'og:title',
          content: 'Virtual Meetings Page',
        },
        {
          name: 'description',
          content:
            'Make It So provides online virtual engagements. This page describes how we can entertain online.',
        },
        {
          property: 'og:description',
          content:
            'Make It So provides online virtual engagements. This page describes how we can entertain online.',
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "virtual-meetings" */ '../views/whatwedo/VirtualMeetings.vue'
      ),
  },
  {
    path: '/what-we-do/exhibitions/congress-booth-training',
    name: 'Congress Booth Training',
    meta: {
      title: 'Congress Booth Training | Make It So - Production and Event Realisation',
      metaTags: [
        {
          property: 'og:title',
          content: 'Congress Booth Training Page',
        },
        {
          name: 'description',
          content:
            'Staff training before an event or exhibition is important to keep your customers engaged. This pages tells you how Make It So can help with that.',
        },
        {
          property: 'og:description',
          content:
            'Staff training before an event or exhibition is important to keep your customers engaged. This pages tells you how Make It So can help with that.',
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "congress-booth-training" */ '../views/whatwedo/CongressBoothTraining.vue'
      ),
  },
  {
    path: '/what-we-do/exhibitions/presentation-skills-training',
    name: 'Presentation Skills Training',
    meta: {
      title: 'Presentation Skills Training | Make It So - Production and Event Realisation',
      metaTags: [
        {
          property: 'og:title',
          content: 'Presentation Skills Training Page',
        },
        {
          name: 'description',
          content:
            'Find out here how Make It So can help with confidence building in presentations.',
        },
        {
          property: 'og:description',
          content:
            'Find out here how Make It So can help with confidence building in presentations.',
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "presentation-skills-training" */ '../views/whatwedo/PresentationSkillsTraining.vue'
      ),
  },
  {
    path: '/what-we-do/the-arts',
    name: 'The Arts',
    meta: {
      title: 'The Arts | Make It So - Production and Event Realisation',
      metaTags: [
        {
          property: 'og:title',
          content: 'The Arts Page',
        },
        {
          name: 'description',
          content:
            'A gallery of posters from past Theatre productions and events. Here you can see what talents we bring to performances in the theatre.',
        },
        {
          property: 'og:description',
          content:
            'A gallery of posters from past Theatre productions and events. Here you can see what talents we bring to performances in the theatre.',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "the_arts" */ '../views/TheArts.vue'),
  },
  {
    path: '/latest-news',
    name: 'Latest News',
    meta: {
      title: 'Latest News | Make It So - Production and Event Realisation',
      metaTags: [
        {
          property: 'og:title',
          content: 'Latest News Page',
        },
        {
          name: 'description',
          content:
            'Find out what Make It So has been up to lately and see what we\'ve done in the past',
        },
        {
          property: 'og:description',
          content:
            'Find out what Make It So has been up to lately and see what we\'ve done in the past',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "latest_news" */ '../views/LatestNews.vue'),
  },
  {
    path: '/portfolio/amgen',
    name: 'Amgen',
    meta: {
      title: 'Amgen | Make It So - Production and Event Realisation',
      transitionName: 'slide',
      metaTags: [
        {
          property: 'og:title',
          content: 'Amgen Client Page',
        },
        {
          name: 'description',
          content:
            'A page describing what work we have done for Agmen with images from previous exhibitions and events.',
        },
        {
          property: 'og:description',
          content:
            'A page describing what work we have done for Agmen with images from previous exhibitions and events.',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "amgen" */ '../views/companies/Amgen.vue'),
  },
  {
    path: '/portfolio/bayer',
    name: 'Bayer',
    meta: {
      title: 'Bayer | Make It So - Production and Event Realisation',
      transitionName: 'slide',
      metaTags: [
        {
          property: 'og:title',
          content: 'Bayer Client Page',
        },
        {
          name: 'description',
          content:
            'A page describing what work we have done for Bayer with images from previous exhibitions and events.',
        },
        {
          property: 'og:description',
          content:
            'A page describing what work we have done for Bayer with images from previous exhibitions and events.',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "bayer" */ '../views/companies/Bayer.vue'),
  },
  {
    path: '/portfolio/takeda',
    name: 'Takeda',
    meta: {
      title: 'Takeda | Make It So - Production and Event Realisation',
      transitionName: 'slide',
      metaTags: [
        {
          property: 'og:title',
          content: 'Takeda Client Page',
        },
        {
          name: 'description',
          content:
            'A page describing what work we have done for Takeda with images from previous exhibitions and events.',
        },
        {
          property: 'og:description',
          content:
            'A page describing what work we have done for Takeda with images from previous exhibitions and events.',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "takeda" */ '../views/companies/Takeda.vue'),
  },
  {
    path: '/portfolio/vifor-pharma',
    name: 'Vifor Pharma',
    meta: {
      title: 'Vifor Pharma | Make It So - Production and Event Realisation',
      transitionName: 'slide',
      metaTags: [
        {
          property: 'og:title',
          content: 'Vifor Pharma Client Page',
        },
        {
          name: 'description',
          content:
            'A page describing what work we have done for Vifor Pharma with images from previous exhibitions and events.',
        },
        {
          property: 'og:description',
          content:
            'A page describing what work we have done for Vifor Pharma with images from previous exhibitions and events.',
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "viforPharma" */ '../views/companies/ViforPharma.vue'
      ),
  },
  {
    path: '/portfolio/sandoz',
    name: 'Sandoz',
    meta: {
      title: 'Sandoz | Make It So - Production and Event Realisation',
      transitionName: 'slide',
      metaTags: [
        {
          property: 'og:title',
          content: 'Sandoz Client Page',
        },
        {
          name: 'description',
          content:
            'A page describing what work we have done for Sandoz with images from previous exhibitions and events.',
        },
        {
          property: 'og:description',
          content:
            'A page describing what work we have done for Sandoz with images from previous exhibitions and events.',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "sandoz" */ '../views/companies/Sandoz.vue'),
  },
  {
    path: '/portfolio/shire',
    name: 'Shire',
    meta: {
      title: 'Shire | Make It So - Production and Event Realisation',
      transitionName: 'slide',
      metaTags: [
        {
          property: 'og:title',
          content: 'Shire Client Page',
        },
        {
          name: 'description',
          content:
            'A page describing what work we have done for Shire with images from previous exhibitions and events.',
        },
        {
          property: 'og:description',
          content:
            'A page describing what work we have done for Shire with images from previous exhibitions and events.',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "shire" */ '../views/companies/Shire.vue'),
  },
  {
    path: '/portfolio/baxter',
    name: 'Baxter',
    meta: {
      title: 'Baxter | Make It So - Production and Event Realisation',
      transitionName: 'slide',
      metaTags: [
        {
          property: 'og:title',
          content: 'Baxter Client Page',
        },
        {
          name: 'description',
          content:
            'A page describing what work we have done for Baxter with images from previous exhibitions and events.',
        },
        {
          property: 'og:description',
          content:
            'A page describing what work we have done for Baxter with images from previous exhibitions and events.',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "baxter" */ '../views/companies/Baxter.vue'),
  },
  {
    path: '/portfolio/pfizer',
    name: 'Pfizer',
    meta: {
      title: 'Pfizer | Make It So - Production and Event Realisation',
      transitionName: 'slide',
      metaTags: [
        {
          property: 'og:title',
          content: 'Pfizer Client Page',
        },
        {
          name: 'description',
          content:
            'A page describing what work we have done for Pfizer with images from previous exhibitions and events.',
        },
        {
          property: 'og:description',
          content:
            'A page describing what work we have done for Pfizer with images from previous exhibitions and events.',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "pfizer" */ '../views/companies/Pfizer.vue'),
  },
  {
    path: '/admin',
    name: 'AdminItem',
    meta: {
      title: 'Admin | Make It So - Production and Event Realisation',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/admin/AdminItem.vue'),
  },
  {
    path: '/admin/login',
    name: 'LoginItem',
    meta: {
      title: 'Login | Make It So - Production and Event Realisation',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/admin/LoginItem.vue'),
  },
  {
    path: '/admin/news-item/add',
    name: 'AddPostItem',
    meta: {
      title: 'Add New Latest News Post | Make It So - Production and Event Realisation',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "add_post_item" */ '../views/admin/AddEditPostItem.vue'
      ),
  },
  {
    path: '/admin/news-item/edit/:id',
    name: 'EditPostItem',
    meta: {
      title: 'Edit New Latest News Post | Make It So - Production and Event Realisation',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "edit_post_item" */ '../views/admin/AddEditPostItem.vue'
      ),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    meta: {
      title: 'Page Not Found | Make It So - Production and Event Realisation',
      metaTags: [
        {
          property: 'og:title',
          content: 'Page not found.',
        },
        {
          name: 'description',
          content:
            'We are sorry but we could not find the page you entered.',
        },
        {
          property: 'og:description',
          content:
            'We are sorry but we could not find the page you entered.',
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "not_found" */ '../views/NotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === 'production' ? '/' : '/'
  ),
  routes,
})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    (el) => el.parentNode.removeChild(el)
  )

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag))

  next()
})
export default router
