<template>
  <section id="contact" class="panel">
    <div class="inset-content">
      <div id="contact-form-wrapper">
        <form id="mailer" @submit.prevent="submitForm">
          <h1>Contact Us</h1>
          <div class="equalHMVWrap eqWrap">
            <div class="equalHMV">
              <label class="control-label">Full Name:</label>
              <div class="formfield">
                <input
                  id="name"
                  v-model="form.name"
                  class="textinput"
                  form="mailer"
                  name="name"
                  required
                  type="text"
                  placeholder="your name..." />
              </div>
              <label class="control-label">Phone:</label>
              <div class="formfield">
                <input
                  id="phone"
                  v-model="form.phone"
                  class="textinput"
                  form="mailer"
                  name="phone"
                  required
                  type="text"
                  placeholder="your phone number..." />
              </div>
            </div>
            <div class="equalHMV">
              <label class="control-label">E-Mail:</label>
              <div class="formfield">
                <input
                  id="email"
                  v-model="form.email"
                  class="textinput"
                  autocomplete="on"
                  form="mailer"
                  name="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  required
                  size="40"
                  type="email"
                  placeholder="your e-mail address..." />
              </div>
              <label class="control-label">Company:</label>
              <div class="formfield">
                <input
                  id="company_name"
                  v-model="form.company_name"
                  class="textinput"
                  form="mailer"
                  name="companyname"
                  required
                  type="text"
                  placeholder="your company name..." />
              </div>
            </div>
            <div style="width: 100%">
              <label class="control-label">Subject:</label>
              <div class="formfield">
                <input
                  id="subject"
                  v-model="form.subject"
                  class="textinput"
                  form="mailer"
                  name="subject"
                  required
                  type="text"
                  placeholder="your message subject..." />
              </div>
              <label class="control-label">Message:</label>
              <div class="formfield">
                <textarea
                  id="message"
                  v-model="form.message"
                  name="message"
                  class="textinput"
                  cols="54"
                  rows="5"
                  required
                  placeholder="your message..." />
              </div>
              <p class="recaptcha_terms">
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a>
                and
                <a href="https://policies.google.com/terms">Terms of Service</a>
                apply.
              </p>
            </div>
          </div>
          <input
            id="recaptcha_response"
            v-model="form.recaptcha_response"
            type="hidden"
            name="recaptcha_response" />
          <input
            id="submit"
            name="submit"
            type="submit"
            form="mailer"
            value="send" />
          <p class="antispam">
            Leave this empty: <input type="text" name="url" />
          </p>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
  import { useToast } from 'vue-toastification'
  import axios from 'axios'
  import { ScrollTrigger, gsap } from 'gsap/all'

  export default {
    name: 'ContactForm',
    setup() {
      // Setup Toast notifcations
      const toast = useToast()
      return { toast }
    },
    data() {
      return {
        form: {
          name: '',
          phone: '',
          email: '',
          company_name: '',
          subject: '',
          message: '',
          recaptcha_response: '',
        },
        contactFormTL: null,
      }
    },
    mounted() {
      gsap.registerPlugin(ScrollTrigger)
    },
    methods: {
      submitForm() {
        // Wait to receive the reCaptcha token before submitting the form.
        let submitButton = document.getElementById('submit')
        this.disableSubmitButton(submitButton)
        this.$recaptcha('login').then((token) => {
          this.form.recaptcha_response = token
          let mailer = process.env.VUE_APP_ROOT_API + 'mailer/mailer.php'
          axios
            .post(
              `${mailer}`,
              this.form, // the data to post
              {
                headers: {
                  'Content-type': 'application/x-www-form-urlencoded',
                },
              }
            )
            .then(async (response) => {
              if (
                response.data === 'Your message has been sent successfully.'
              ) {
                this.resetForm()
                this.toast.success(response.data)
                await new Promise((resolve) => setTimeout(resolve, 600000))
                this.enableSumbitButton()
              } else {
                this.resetForm()
                this.toast.error(response.data)
                this.enableSumbitButton(submitButton)
              }
            })
            .catch(async (error) => {
              this.toast.error(
                this.error === 'Error: Network Error'
                  ? `An unknown error occured: ${error}`
                  : "Sorry there appears to be a network connection issue and we couldn't send your message.\nPlease try again after 10 minutes."
              )
              await new Promise((resolve) => setTimeout(resolve, 600000))
              document.getElementById('submit').disabled = false
              submitButton.style.pointerEvents = 'auto'
            })
        })
      },
      enableSumbitButton(submitButton) {
        document.getElementById('submit').disabled = false
        submitButton.style.pointerEvents = 'auto'
      },
      disableSubmitButton(submitButton) {
        submitButton.setAttribute('disabled', true)
        submitButton.style.pointerEvents = 'none'
      },
      resetForm() {
        // Replace data object with the defaults for the component
        Object.assign(this.$data, this.$options.data.apply(this))
      },
    },
  }
</script>

<style lang="scss" scoped>
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  #section-header {
    align-items: normal;
  }

  #contact-form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    width: 100%;
    max-width: 1440px;
  }

  /* Contact form */

  #contact {
    font-size: 1.5vh;
    text-align: left;
    background-color: black;
  }

  .contactfieldtitle {
    color: white;
  }

  .antispam {
    display: none;
  }

  .has-error {
    color: crimson;
  }

  #mailer {
    margin: 0 auto;
    max-height: 75vh;
    overflow: hidden;
    width: 75vw !important;

    @media #{$mq-tiny} {
      width: 80vw !important;
    }
  }

  #mailer > div > div > div input {
    -webkit-appearance: none;
    appearance: none;
    border: 1px rgb(131, 8, 8);
    margin: 7px 0px 15px;
    padding: 5px;
    flex-wrap: wrap;
    width: 100%;
  }

  #message {
    -webkit-appearance: none;
    appearance: none;
    border: 1px rgb(158, 9, 9);
    margin: 7px 15px 0px 0px;
    padding: 20px;
    resize: block;
    width: 100%;
    max-width: 100%;
    max-height: 13vh;
    resize: vertical;
    min-height: 5.56vh;
  }

  .control-label {
    text-transform: uppercase;
  }

  .col {
    flex: 1;
  }

  #response {
    overflow: scroll;
    max-height: 10vh;
  }
  .flex-grid {
    display: block;
  }

  .eqWrap {
    display: flex;
  }

  .equalHMVWrap {
    flex-wrap: wrap;
  }

  .equalHMV {
    width: 50%;
    padding-right: 5px;
  }

  .equalHMV:nth-child(2) {
    padding-right: 0px;
    padding-left: 5px;
  }
  .recaptcha_terms {
    color: rgb(61, 61, 61);
    font-size: 8pt;
  }
</style>
