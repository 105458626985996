<template>
  <div>
    <div ref="loader" id="loader" />
    <div id="buffering">
      <div id="loadingAnim">
        <span class="loading-block" v-for="n in 9" :key="n"></span>
      </div>
      <div id="msg">Loading<br />Please Wait...</div>
    </div>
    <app-nav ref="appNavComponent" />
    <main id="main">
      <router-view @showTopNavHeader="showTopNavHeader" v-slot="{ Component }">
        <transition mode="out-in" @enter="enter" @leave="leave" @afterEnter="afterEnter">
          <keep-alive>
            <component :is="Component" :key="$route.name" />
          </keep-alive>
        </transition>
      </router-view>
      <ContactForm />
    </main>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import AppNav from '@/components/AppNav.vue'
import ContactForm from '@/components/ContactForm'
import { gsap } from 'gsap'
import { ScrollToPlugin, ScrollTrigger } from 'gsap/all'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

export default {
  components: { AppNav, ContactForm },
  setup() {
    const initialLoad = ref(true)
    const currentPage = ref('')
    const visible = ref(false)
    const loader = ref(null)
    const pagesThatHaveBackArrow = [
      'iPad Interactive Voting System',
      'Keypad Interactive Voting System',
      'Virtual Meetings',
      'Congress Booth Training',
      'Presentation Skills Training',
      'Amgen',
      'Bayer',
      'Takeda',
      'Vifor Pharma',
      'Sandoz',
      'Shire',
      'Baxter',
      'Pfizer',
      'AddPostItem',
      'EditPostItem',
    ]
    const pagesThatHaveScrollDownArrow = [
      'Home',
      'Portfolio',
      'The Arts',
      'Latest News',
    ]
    let animations = {}

    onMounted(() => {
      setupInitialAnimations()
      document.onreadystatechange = handleDocumentReadyState
    })

    const setupInitialAnimations = () => {
      gsap.to('#buffering', { autoAlpha: 1, delay: 0, duration: 0 })
      gsap.set('#contact', { autoAlpha: 0 })
    }

    const handleDocumentReadyState = () => {
      if (document.readyState === 'complete' && currentPage.value !== 'Home') {
        gsap.to('#buffering', { autoAlpha: 0, delay: 0, duration: 1 })
      }
    }

    const showTopNavHeader = () => {
      gsap.set('#top-nav-bar', { display: 'initial' })
      animations.TopNavBarMenuTL = createFadeInAnimation('#top-nav-bar')

      toggleElementVisibility('.prev-button', pagesThatHaveBackArrow.includes(currentPage.value) && window.history.length > 1, createFadeInAnimation)
      toggleElementVisibility('.scroll-down', pagesThatHaveScrollDownArrow.includes(currentPage.value), createScrollAnimation)
    }

    const toggleElementVisibility = (selector, condition, animationCallback) => {
      if (condition) {
        gsap.set(selector, { display: 'initial' })
        animations[selector] = animationCallback(selector)
      } else {
        gsap.set(selector, { display: 'none' })
      }
    }

    const createFadeInAnimation = (selector) => {
      return gsap.timeline().fromTo(
        selector,
        { autoAlpha: 0, y: -10 },
        { autoAlpha: 1, duration: 0.3, y: 0, onReverseComplete: () => gsap.set(selector, { display: 'none' }) }
      )
    }

    const createScrollAnimation = (selector) => {
      return gsap.timeline({ repeat: -1, delay: 3, id: 'scrollDownTimeline' })
        .fromTo(selector, { autoAlpha: 0, y: -30 }, { autoAlpha: 0.7, duration: 0.3, y: 0 })
        .to('.arrow', { y: 12, ease: 'power1.inOut', repeat: -1, yoyo: true })
    }

    const hideTopNavHeader = () => {
      Object.values(animations).forEach(animation => animation.reverse())
    }

    const leave = (element, done) => {
      hideTopNavHeader()
      if (document.querySelector('#main-nav').classList.contains('shown')) {
        hideNavigationMenu(done)
      } else {
        createWipeFromRightToLeft(done)
      }
    }

    const enter = (element, done) => {
      gsap.set('#contact', { autoAlpha: 1 })
      if (currentPage.value !== 'Home') {
        removeWipeFromRightToLeft(done)
        setupContactFormAnimation()
      } else {
        visible.value = false
        gsap.set(loader.value, { scaleX: 0 })
      }
    }

    const setupContactFormAnimation = () => {
      animations.contactFormTL = gsap.timeline({ scrollTrigger: { toggleActions: 'play pause resume pause', trigger: '#contact', id: 'contactFormTrigger' } })
        .fromTo('.scroll-down', { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.3 })
        .fromTo('#mailer *', { autoAlpha: 0, x: -25, y: -25 }, { autoAlpha: 1, x: 0, y: 0, stagger: 0.01 })
    }

    const afterEnter = () => {
      window.scrollTo({ top: 0 })
      if (currentPage.value !== 'Home') {
        showTopNavHeader()
      }
    }

    const createWipeFromRightToLeft = (done) => {
      gsap.fromTo(loader.value, { scaleX: 0, autoAlpha: 1, duration: 0.5, transformOrigin: 'right top' }, { scaleX: 1, ease: 'back.inOut(1.2)', onComplete: done })
    }

    const removeWipeFromRightToLeft = (done) => {
      gsap.fromTo(loader.value, { scaleX: 1, transformOrigin: 'left top' }, { duration: 0.5, scaleX: 0, ease: 'back.inOut(1.2)', onComplete: done })
    }

    const hideNavigationMenu = (done) => {
      gsap.to('#navlinkhalf', { display: 'none', autoAlpha: 0, translateY: -50, duration: 0.3, onComplete: () => {
        gsap.to('#main-nav', { translateX: '-100vw', duration: 0.3, ease: 'elastic.inOut(1, 1)', onComplete: () => createWipeFromRightToLeft(done) })
        document.querySelector('#main-nav').classList.remove('shown')
      }})
    }

    return { initialLoad, currentPage, visible, loader, showTopNavHeader, leave, enter, afterEnter }
  }
}
</script>

<style lang="scss">
@import 'src/assets/stylesheets/loader.scss';
@import 'src/assets/stylesheets/main.scss';

#loader {
  position: fixed;
  width: 0vw;
  height: 100%;
  pointer-events: none;
  background-color: $makeitsopurple;
  top: 0;
  left: 0;
  z-index: 9003;
}

#buffering {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  z-index: 10001;
  text-align: center;
  background-color: rgb(70, 15, 31);
  visibility: hidden;
}

#msg {
  position: absolute;
  width: 100%;
  bottom: 20vmin;
  font-size: 2vmin;
  color: rgb(87, 43, 55);
}
</style>