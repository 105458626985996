import { createApp } from 'vue'
import { createStore } from 'vuex'
import Toast, { POSITION } from 'vue-toastification'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import App from './App.vue'
import router from './router'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueLazyload from 'vue-lazyload' // Import Vue Lazyload

// Global state store
const store = createStore({
  state: {
      token: null
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    }
  }
})

// Notification options
const toastOptions = {
  position: POSITION.BOTTOM_RIGHT,
  timeout: 16000,
  transition: "Vue-Toastification__slideBlurred",
  showCloseButtonOnHover: true,
  maxToasts: 4,
}

const app = createApp(App)
.use(store)
.use(Toast, toastOptions)
.use(router)
.use(VuePlyr)
.use(VueReCaptcha, {
  siteKey: '6Le76ocUAAAAAMHFOWXKPZLNbeInWyttGRMSC03f',
  loaderOptions: {
    useRecaptchaNet: false,
    autoHideBadge: true,
    useEnterprise: false,
    
  },
})
.use(VueLazyload, { // Register Vue Lazyload
    loading: 'path/to/loading-image.png', // Set a placeholder loading image
    error: 'path/to/error-image.png', // Set an error image if the image fails to load
  })
app.mount('#mis-site')