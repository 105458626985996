<template>
  <nav ref="topNavBar" id="top-nav-bar" style="display: none">
    <div id="mini-nav">
      <a href="/" @click.prevent="gotoHome"> HOME </a> |
      <a id="contactlink" href="#contact" @click.prevent="goToContact"
        >CONTACT</a
      >
    </div>
    <div id="burger" ref="burger">
      <svg
        id="burger-svg"
        width="35px"
        height="35px"
        class="openmenu"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 35 35"
        preserveAspectRatio="xMidYMid meet"
        @click="openNavMenu">
        <rect
          x="0"
          y="0"
          width="35"
          height="2"
          style="fill: white; fill-rule: nonzero"
          stroke="black"
          stroke-opacity="0.5"
          stroke-width="1" />
        <g transform="matrix(1,0,0,1,-0.00250554,14)">
          <rect
            x="0"
            y="0"
            width="35"
            height="2"
            style="fill: white; fill-rule: nonzero"
            stroke="black"
            stroke-opacity="0.5"
            stroke-width="1" />
        </g>
        <rect
          x="0"
          y="28"
          width="35"
          height="2"
          style="fill: white; fill-rule: nonzero"
          stroke="black"
          stroke-opacity="0.5"
          stroke-width="1" />
      </svg>
    </div>
    <div
      id="main-nav"
      ref="mainMenu"
      @click="!isTouchDevice ? openNavMenu() : null">
      <button v-if="isTouchDevice" id="close-button" @click="openNavMenu()">
        <svg width="50" height="50">
          <g stroke-width="3" :stroke="'rgba(255, 255, 255, 1)'">
            <line x1="5" y1="5" x2="35" y2="35" />
            <line x1="5" y1="35" x2="35" y2="5" />
          </g>
        </svg>
      </button>
      <div id="main-nav-content" ref="mainNavContent">
        <ol>
          <ul>
            <router-link ref="navLink" to="/about-us"
              ><span class="navlink about-us">About Us</span>
            </router-link>
          </ul>
          <ul>
            <router-link ref="navLink" to="/portfolio"
              ><span class="navlink portfolio">Portfolio</span>
            </router-link>
          </ul>
          <div id="what-we-do">
            <ul class="whatwedo">
              What we do
            </ul>
            <nav id="navlinkhalf" ref="navLinkHalf">
              <ul>
                <router-link ref="navLink" to="/what-we-do/exhibitions"
                  ><span class="what-we-do-item exhibitions">Exhibitions</span>
                </router-link>
              </ul>
              <br />
              <ul>
                <router-link ref="navLink" to="/what-we-do/the-arts"
                  ><span class="what-we-do-item arts">The Arts</span>
                </router-link>
              </ul>
            </nav>
          </div>
          <ul>
            <router-link ref="navLink" to="/latest-news"
              ><span class="navlink latest">Latest News</span>
            </router-link>
          </ul>
        </ol>
      </div>
    </div>
  </nav>
  <button
    class="prev-button"
    aria-label="Click to go back to the previous page"
    @click="goBack()">
    <svg width="30" height="40" viewBox="0 0 30 40">
      <polyline
        points="19 5 5 20 19 35"
        stroke-width="3"
        stroke-linecap="butt"
        fill="none"
        stroke-linejoin="round"
        :stroke="interfaceColor" />
    </svg>
  </button>
  <div class="scroll-down">
    Scroll down
    <div class="arrow"></div>
  </div>
</template>

<script>
import {
  Elastic,
  gsap,
  ScrollToPlugin,
  ScrollTrigger,
  TextPlugin,
  CSSRulePlugin,
} from 'gsap/all'
export default {
  data() {
    return {
      menuToggle: null,
      mainNavMenuTimeline: null,
      menuNavVisible: false,
      mainMenu: '',
      mainNavContent: '',
      navLink: '',
      justClickedBurgerMenu: false,
    }
  },
  props: {
    interfaceColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0.85)',
    },
    background: {
      type: String,
      default: 'rgba(255, 255, 255, 0.1)',
    },
  },
  computed: {
    isTouchDevice() {
      if (
        'ontouchstart' in window ||
        navigator.MaxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      ) {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    const {
      topBurger,
      botBurger,
      midBurger,
      mainMenu,
      mainNavContent,
      navLink,
    } = this.$refs
    gsap.registerPlugin(
      ScrollToPlugin,
      ScrollTrigger,
      TextPlugin,
      Elastic,
      CSSRulePlugin
    )

    // Fullscreen nav menu animation
    this.mainNavMenuTimeline = gsap
      .timeline({
        paused: true,
        reversed: true,
        id: 'mainNavMenuTimeLine',
      })
      .set('#navlinkhalf', { display: 'none' })
      .set(
        mainMenu,
        {
          transformOrigin: 'top left',
          ease: 'power4.inOut',
          rotate: 45,
          autoAlpha: 1,
          display: 'block',
          scale: 0,
          x: '4vw',
          y: '5.8vh',
        },
        '0'
      )
      .set(
        mainNavContent,
        {
          display: 'flex',
          perspective: 400,
        },
        '0'
      )
      .set(
        navLink,
        {
          transformStyle: 'preserve-3d',
          autoAlpha: 0,
        },
        '0'
      )
      .fromTo(
        mainMenu,
        {
          scaleX: 0,
        },
        {
          scaleX: 1,
          ease: 'back.out(1.7)',
          duration: 0.1,
        },
        '>'
      )
      .to(
        mainMenu,
        {
          scale: 1,
          rotate: 0,
          x: 0,
          y: 0,
          ease: Elastic.easeOut.config(1.15, 2.5),
          duration: 0.1,
        },
        '>'
      )

      // navLink's fly in

      .fromTo(
        '.navlink, .whatwedo',
        {
          z: -30,
          y: +100,
          translateY: 100,
          scale: 1.4,
          autoAlpha: 0,
          color: 'rgb(70, 15, 31)',
          display: 'inline-block',
        },
        {
          duration: 0.1,
          z: 0,
          y: 0,
          translateY: 0,
          scale: 1,
          autoAlpha: 1,
          stagger: 0.05,
          color: 'white',
          onComplete: () => {
            document.querySelector('#main-nav').classList.add('shown')
            this.menuNavVisible = true
            this.attachEventsToMainNavLinks()
          },
        },
        '>'
      )
    document.body.height = window.innerHeight
  },
  methods: {
    async openNavMenu() {
      const delay = (ms) => new Promise((res) => setTimeout(res, ms))
      if (this.justClickedBurgerMenu != true) {
        this.mainNavMenuTimeline.reversed()
          ? this.mainNavMenuTimeline.restart()
          : this.mainNavMenuTimeline.reverse()
        this.justClickedBurgerMenu = true
        await delay(100)
        this.justClickedBurgerMenu = false
      }
    },
    goToContact() {
      gsap.to(window, {
        duration: 0.5,
        scrollTo: '#contact',
      })
    },
    gotoHome() {
      if (this.$route.name === 'Home') {
        gsap.to(window, {
          duration: 0.5,
          scrollTo: 'body',
        })
      } else {
        this.$router.push({ path: '/' })
      }
    },
    whatWeDoMouseEnter() {
      gsap.fromTo(
        '#navlinkhalf',
        {
          translateY: -50,
        },
        {
          autoAlpha: 1,
          display: 'flex',
          translateY: 0,
          delay: 0.2,
        }
      )
    },
    whatWeDoOnMouseLeave() {
      gsap.fromTo(
        '#navlinkhalf',
        {
          translateY: 0,
        },
        {
          autoAlpha: 0,
          display: 'none',
          translateY: -50,
        }
      )
    },
    attachEventsToMainNavLinks() {
      const whatWeDoLink = document.querySelectorAll('#what-we-do')

      whatWeDoLink[0].addEventListener('mouseleave', this.whatWeDoOnMouseLeave)

      whatWeDoLink[0].addEventListener('mouseenter', this.whatWeDoMouseEnter)

      const whatWeDoLinkItems = document.querySelectorAll('.what-we-do-item')

      let self = this
      whatWeDoLinkItems.forEach(function (item) {
        item.ontouchend = (e) => {
          switch (e.srcElement.innerHTML) {
            case 'Exhibitions':
              self.$router.push({ path: '/what-we-do/exhibitions' })
              break
            case 'The Arts':
              self.$router.push({ path: '/what-we-do/the-arts' })
              break
          }
        }
      })
    },
    removeEventsOnWhatWeDoNavLink() {
      const whatWeDoLink = document.querySelectorAll('#what-we-do')
      whatWeDoLink[0].removeEventListener(
        'mouseleave',
        this.whatWeDoOnMouseLeave()
      )
      whatWeDoLink[0].removeEventListener(
        'mouseenter',
        this.whatWeDoMouseEnter()
      )
    },
    goBack() {
      this.$router.back()
    },
  },
}
</script>

<style lang="scss" scoped>
$about-us-letterspacing: 3.2vmin;
$portfolio-letterspacing: 2.1vmin;
$whatwedo-letterspacing: 1.35vmin;
$latest-letterspacing: 1.15vmin;
$arts-letterspacing: 0.975vmin;
$exhibitions-letterspacing: 0.975vmin;
#top-nav-bar {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'burger mini-nav';
  align-items: center;
  position: fixed;
  width: 100vw;
  z-index: 1001;
  visibility: hidden;
  opacity: 0;
  padding-top: 5vh;
}

#burger {
  position: relative;
  padding-left: 4vh;
  padding-top: 1vh;
  margin: 0px;
  grid-area: burger;

  @media #{$mq-tiny} {
    padding-left: 4vh;
  }

  @media #{$mq-small} {
    padding-left: 5vh;
  }
}

#burger-svg {
  width: 5vh;
  height: auto;
  filter: drop-shadow(0px 0px 0.3vmin rgb(0 0 0 / 1));

  @media #{$mq-tiny} {
    width: 4vh;
  }

  @media #{$mq-tiny-small} {
    width: 2vh;
  }

  @media #{$mq-small} {
    width: 3vh;
  }
}

#close-button {
  top: 0;
  left: 0;
  position: absolute;
  padding-left: 4vh;
  padding-top: 5vh;
  margin: 0px;
  position: absolute;
  background: transparent;
  border: 0;
  line-height: 0;
  outline: none;
  cursor: pointer;

  @media #{$mq-tiny} {
    padding-left: 4vh;
  }

  @media #{$mq-small} {
    padding-left: 5vh;
  }
}

#burger:hover {
  cursor: pointer;
}

#mini-nav {
  font-family: $alt-font, sans-serif, Arial;
  color: rgb(255, 255, 255);
  filter: drop-shadow(0px 0px 0.3vmin rgb(0 0 0 / 1));
  font-size: 3.8vh;
  font-weight: 400;
  background-color: transparent;
  grid-area: mini-nav;
  justify-self: end;
  text-align: right;
  margin-right: 5vmin;
  @media screen and (orientation: landscape) {
    font-size: 6.65vmin;
  }

  @media screen and (orientation: portrait) {
    font-size: 6.65vmin;
  }

  @media #{$mq-tiny-small} {
    padding-right: 2.2vh;
  }
  @media #{$mq-tiny} {
    font-size: 8vmin;
    margin-right: 9vmin;
  }
  @media #{$mq-small} {
    padding-right: 1.5vh;
    font-size: 4.4vmin;
  }
  @media #{$mq-medium} {
    font-size: 4.1vmin;
    margin-right: 2vmin;
  }
  @media #{$mq-large} {
    font-size: 4.1vmin;
    margin-right: 2vmin;
  }
  @media #{$mq-small} and (orientation: landscape) {
    font-size: 4vmin;
  }
  @media all and (width: 568px) and (height: 320px) and (orientation: landscape) {
    font-size: 7.3vmin;
  }

  @media all and (width: 823px) and (height: 411px) and (orientation: landscape) {
    font-size: 7.3vmin;
  }

  @media all and (width: 731px) and (height: 411px) and (orientation: landscape) {
    font-size: 7.3vmin;
  }

  @media all and (width: 375px) and (height: 667px) and (orientation: portrait) {
    font-size: 7.3vmin;
  }
  @media all and (width: 667px) and (height: 375px) and (orientation: landscape) {
    font-size: 7.3vmin;
  }
  @media all and (width: 736px) and (height: 414px) and (orientation: landscape) {
    font-size: 7.3vmin;
  }
  @media all and (width: 414px) and (height: 736px) and (orientation: portrait) {
    font-size: 7.3vmin;
  }
  @media all and (width: 812px) and (height: 375px) and (orientation: landscape) {
    font-size: 7.8vmin;
  }
  @media all and (width: 375px) and (height: 812px) and (orientation: portrait) {
    font-size: 7.7vmin;
  }

  @media all and (width: 926px) and (height: 428px) and (orientation: landscape) {
    font-size: 7vmin;
  }

  @media all and (width: 428px) and (height: 926px) and (orientation: portrait) {
    font-size: 7vmin;
  }
}

#mini-nav a {
  text-decoration: none;
  color: white;
}

#mini-nav a:hover {
  text-decoration: none;
  color: rgb(212, 211, 211);
}

#main-nav {
  position: fixed;
  cursor: url('../assets/images/close-cursor.svg'), pointer;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1001;
  background-color: $makeitsopurple;
  text-align: right;
  overflow: hidden;
  // -webkit-transform: translateZ(1000px);
  // transform: translateZ(1000px);
}

.navlink,
.whatwedo {
  font-family: 'Josefin Sans Bold', sans-serif, Arial;
  position: relative;
  font-size: 7vmin;
  text-align: right;
  color: white;
  text-transform: uppercase;
  flex-direction: column;
  text-decoration: none;
  margin-bottom: 5vh;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
  display: inline-block;
  cursor: pointer;
  direction: rtl;
  background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
  background-origin: content-box;
  background-position: 0% 100%; /*OR bottom left*/
  background-size: 0% 0.2vh;
  background-repeat: no-repeat;
  transition: background-size 0.3s, background-position 0s 0.3s; /*change after the size immediately*/
  @media #{$mq-tiny-small} {
    font-size: 9vmin;
  }
  @media #{$mq-tiny} {
    font-size: 10vmin;
  }
  @media #{$mq-small} {
    font-size: 8vmin;
  }
}

.what-we-do-item {
  display: inline-block;
  direction: rtl;
  background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
  background-origin: content-box;
  background-position: 0% 100%; /*OR bottom left*/
  background-size: 0% 0.2vh;
  background-repeat: no-repeat;
  transition: background-size 0.3s, background-position 0s 0.3s; /*change after the size immediately*/
}

.what-we-do-item:hover {
  background-position: 100% 100%; /*OR bottom right*/
  background-size: 100% 0.2vh;
  width: fit-content;
  height: fit-content;
}

.navlink:hover {
  background-position: 100% 100%; /*OR bottom right*/
  background-size: 100% 0.2vh;
  width: fit-content;
  height: fit-content;
}

#main-nav-content > ol > ul:last-child > a {
  padding-bottom: 0 !important;
}

#navlinkhalf > ul:last-child {
  padding-bottom: 5vh;
}

.about-us {
  // letter-spacing: $about-us-letterspacing;
  letter-spacing: $about-us-letterspacing;
  text-indent: -$about-us-letterspacing;
}

.portfolio {
  letter-spacing: $portfolio-letterspacing;
  text-indent: -$portfolio-letterspacing;
}

.whatwedo {
  letter-spacing: $whatwedo-letterspacing;
  text-indent: -$whatwedo-letterspacing;
}

.latest {
  letter-spacing: $latest-letterspacing;
  text-indent: -$latest-letterspacing;
}

.arts {
  letter-spacing: $arts-letterspacing;
  text-indent: -$arts-letterspacing;
  display: inline-block;
}

.exhibitions {
  letter-spacing: $exhibitions-letterspacing;
  text-indent: -$exhibitions-letterspacing;
  display: inline-block;
}

#navlinkhalf {
  flex-direction: column;
  color: rgb(255, 255, 255);
  font-family: 'Josefin Sans Light', sans-serif, Arial;
  font-size: 5.6vmin;
  text-transform: uppercase;
  display: none;
  justify-content: space-evenly;
}

.navlink,
#navlinkhalf a,
.navlink-whatwedo,
.navlink-whatwedo a {
  text-decoration: none;
  color: white;
  position: relative;
  text-transform: uppercase;
  white-space: nowrap;
}

#main-nav-content {
  margin-top: calc(50vh - 20vh);
  width: 100%;
  flex-direction: column;
  height: 46.2vh;
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
}
.prev-button {
  top: 50%;
  transform: translate(0, -50%);
  left: 4vh;
  position: fixed;
  z-index: 840;
  background: transparent;
  border: 0;
  line-height: 0;
  outline: none;
  padding: 7px;
  cursor: pointer;
  opacity: 0;
  display: none !important;

  @media #{$mq-tiny-small} {
    display: block !important;
  }
  @media #{$mq-medium} {
    display: block !important;
  }

  @media #{$mq-large} {
    display: block !important;
  }

  @media #{$mq-xlarge} {
    display: block !important;
  }

  @media #{$mq-xxlarge} {
    display: block !important;
  }
}

.prev-button:before {
  content: '';
  position: absolute;
  height: 400%;
  width: 400%;
  left: -150%;
  top: -150%;
  opacity: 0.5;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 55%,
    rgba(0, 0, 0, 0) 99%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 55%,
    rgba(0, 0, 0, 0) 99%,
    rgba(0, 0, 0, 0) 100%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 55%,
    rgba(0, 0, 0, 0) 99%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#00000000',GradientType=1 );
  z-index: -1;
}

/* SCROLL DOWN */

.scroll-down {
  position: fixed;
  bottom: 3vh;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  font-weight: 400;
  text-transform: uppercase;
  font-family: 'Bebas Neue Pro';
  filter: drop-shadow(0px 0px 0.3vmin rgb(0 0 0 / 1));
  overflow: visible;
  z-index: 2;
  display: none;
  opacity: 0;
  font-size: 2.4vmin;
  @media #{$mq-tiny} {
    font-size: 4.2vmin;
  }
}

.scroll-down .arrow {
  position: relative;
  top: 0px;
  margin: 0 auto;
  width: 1.6vh;
  height: 1vh;
  background-image: url('../assets/images/down-arrow.svg');
  background-size: contain;
  background-repeat: no-repeat;
}
</style>